
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },
  emits: ["displayActor"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zActeur: {},
    });
    const tableHeader = ref([
      { name: "Ref. Arkheia", key: "act_ark_n_code", sortable: false,},
      { name: "Catégorie", key: "act_c_categorie", sortable: false,},
      { name: "Raison sociale", key: "act_c_raison_sociale", sortable: false,},
      { name: "Adresse", key: "act_c_adr", sortable: false,},
      { name: "Coordonnées", key: "act_c_phone", sortable: false,},
      { name: "",  key: "act_n_seq", sortable: false,},
    ]);

    const tableFilters = ref([
      { name: "Catégorie", key: "act_c_categorie", label: "act_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zActeur = d;
    };

    const returnType = (type) => {
      let mType = ""
      switch (type) {
        case "1":
          mType = "Collectivité"
          break;
        case "2":
          mType = "Personne"
          break;
        case "3":
          mType = "Famille"
          break;
        default:
          mType = "Type inconnu"
      }
      return mType;
    }

    const displayActor = (d) => {
      emit("displayActor", d);
    }

    onMounted(async () => {

      let myListLiv = {} as any;

      myListLiv = await getAxios("/getSVProducteur");

      state.myList = myListLiv.results;
      state.initialMyList = myListLiv.results;

      state.zActeur = state.myList[0];

      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      returnType,
      displayActor
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
